<template>
  <div class="row g-2">
    <div class="col-lg-6">
      <div class="card mb-2">
        <div class="card-header bg-body">{{ $t("order.order") }}</div>
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <span class="text-muted mb-0 d-block">{{
              $t("order.address_id")
            }}</span>
            <span class="text-body d-block text-uppercase font-monospace"
              >-</span
            >
          </div>
          <div class="list-group-item">
            <span class="text-muted mb-0 d-block">{{ $t("order.label") }}</span>
            <span class="text-body d-block text-uppercase font-monospace"
              >-</span
            >
          </div>
          <div class="list-group-item">
            <span class="text-muted mb-0 d-block">{{
              $t("order.verification_type")
            }}</span>
            <span class="text-body d-block text-uppercase font-monospace"
              >-</span
            >
          </div>
        </div>
      </div>

      <div class="card mb-2">
        <div class="card-header bg-body">{{ $t("order.preview") }}</div>
        <div class="list-group list-group-flush">
          <div
            class="list-group-item d-flex align-items-center"
            style="height: 61px"
          >
            <div class="flex-grow-1">
              <div
                class="
                  fw-bold
                  text-decoration-none text-body
                  font-monospace
                  small
                "
              ></div>
              <div class="small mb-0 font-monospace">
                <div class="text-muted text-decoration-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card mb-2 border-primary">
        <div class="card-header bg-body text-center">
          {{ $t("order.make_a_payment") }}
        </div>
        <div class="list-group list-group-flush">
          <div class="list-group-item text-center py-3">
            <img
              class="img-fluid"
              src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&amp;data=MCIHAQVWZH2AB5BB5NP63FBSIREG77LQZZNUVKD2LN2IOCLOT6N72AAAAAAAAAARLQOKK"
            />
          </div>
          <div class="list-group-item">
            <span class="text-muted mb-0 d-block"
              >{{ $t("order.send_payment_to") }}
            </span>
            <span class="text-body d-block text-uppercase font-monospace small"
              >-</span
            >
          </div>
          <div class="list-group-item">
            <span class="text-muted mb-0 d-block">{{
              $t("order.expires_in")
            }}</span>
            <span class="text-body d-block font-monospace small">-</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "OrderSkeleton",
  components: {},
  setup() {
    return {};
  },
});
</script>
