<template>
  <div
    class="
      alert
      border-warning
      bg-warning bg-opacity-10
      d-flex
      align-items-center
      mb-2
      py-2
    "
    role="alert"
  >
    <span
      class="
        flex-shrink-0
        bg-warning bg-opacity-25
        rounded-3
        d-flex
        align-self-center
        me-3
      "
      style="height: 36px; width: 36px"
    >
      <i class="bi bi-info fs-5 align-self-center mx-auto text-warning"></i>
    </span>
    <div>
      <span class="fw-bold text-warning font-monospace small d-block">{{
        $t("order.important")
      }}</span>
      <span class="small">{{
        $t("order.payment_needs_to_be_made_from_account")
      }}</span>
    </div>
  </div>

  <order-skeleton v-if="loading" />
  <order-skeleton v-else-if="error" />
  <div v-else class="row g-2">
    <div class="col-lg-6">
      <div class="card mb-2">
        <div class="card-header bg-body">
          {{ $t("order.order") + $route.params.order_id }}
        </div>
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <span class="text-muted mb-0 d-block">{{
              $t("order.address_id")
            }}</span>
            <span class="text-body d-block text-uppercase font-monospace">{{
              order.accountid
            }}</span>
          </div>
          <div class="list-group-item">
            <span class="text-muted mb-0 d-block">{{ $t("order.label") }}</span>
            <span class="text-body d-block text-uppercase font-monospace">
                {{ order.label_name }}
            </span>
          </div>
          <div class="list-group-item">
            <span class="text-muted mb-0 d-block">{{
              $t("order.verification_type")
            }}</span>
            <span class="text-body d-block text-uppercase font-monospace">{{
              order.order_type
            }}</span>
          </div>
        </div>
      </div>

      <div class="card mb-2">
        <div class="card-header bg-body">{{ $t("order.preview") }}</div>
        <div class="list-group list-group-flush">
          <div class="list-group-item d-flex align-items-center">
            <div class="flex-grow-1">
              <router-link
                :to="{
                  name: 'AccountDetailsRoute',
                  params: {
                    acc_id: order.accountid,
                  },
                }"
                class="
                  fw-bold
                  text-decoration-none text-body
                  font-monospace
                  small
                "
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('order.label')"
                v-tooltip
              >
                <i class="bi bi-patch-check-fill text-primary fs-5 text-truncate" 
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    data-bs-original-title="Verified Account">
                </i>
                {{ order.label_name }}
              </router-link>
              <p class="small mb-0 font-monospace">
                <router-link
                  :to="{
                    name: 'AccountDetailsRoute',
                    params: {
                      acc_id: order.accountid,
                    },
                  }"
                  class="text-muted text-decoration-none"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('order.source_account')"
                  v-tooltip
                  v-middle-ellipsis="{
                    size: 6,
                    text: order.accountid,
                  }"
                >
                </router-link>
              </p>
            </div>
            <div
              v-if="order.order_type === 'verified'"
              class="flex-shrink-0 me-2"
            >
              <i
                class="bi bi-patch-check-fill text-primary fs-5"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('order.verified')"
                v-tooltip
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card mb-2 border-primary">
        <div class="card-header bg-body text-center">
          {{ $t("order.make_a_payment") }}
        </div>
        <div class="list-group list-group-flush">
          <div class="list-group-item text-center py-3">
            <vue-qrcode
              :value="account"
              :width="150"
              :margin="0"
              :scale="0"
              :color="{ dark: '#000000ff' }"
              :type="'image/png'"
              :quality="0.92"
            />
          </div>
          <div class="list-group-item">
            <span class="text-muted mb-0 d-block"
              >{{ $t("order.send_payment_to") }}
              <router-link
                to="#"
                class="float-end text-decoration-none small"
                @click="copy(account)"
                >{{ $t("order.copy") }}</router-link
              ></span
            >
            <span
              class="text-body d-block text-uppercase font-monospace small"
              >{{ account }}</span
            >
          </div>
          <div class="list-group-item">
            <span class="text-muted mb-0 d-block">{{
              $t("order.expires_in")
            }}</span>
            <span class="text-body d-block font-monospace small">{{
              formatOrderExpirationTime(order.expiration_time)
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import OrderSkeleton from "@/components/skeletons/OrderSkeleton.vue";
import { useRoute } from "vue-router";
import { formatOrderExpirationTime } from "@/utils/date.js";
import VueQrcode from "vue-qrcode";

export default defineComponent({
  name: "Order",
  components: { OrderSkeleton, VueQrcode },
  setup() {
    const store = useStore();
    const route = useRoute();
    const account = "GAI3GJ2Q3B35AOZJ36C4ANE3HSS4NK7WI6DNO4ZSHRAX6NG7BMX6VJER";
    store.dispatch("accounts/showOrder", route.params.order_id);

    const order = computed(() => store.getters["accounts/getOrder"].data);
    const loading = computed(() => store.getters["accounts/getOrder"].loading);
    const error = computed(() => store.getters["accounts/getOrder"].error);

    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };

    return {
      loading,
      error,
      order,
      route,
      copy,
      account,
      formatOrderExpirationTime,
    };
  },
});
</script>

<style>
</style>
